<template>
  <v-app id="inspire">
    <!-- MENU LATERAL DE CAJA -->
    <v-navigation-drawer
      v-model="$store.state.carrito.carritovisible"
      app
      clipped
      right
      class="elevation-0"
      width="350"
      temporary
    >
      <!-- Contenido del menu de la derecha -->
      <Caja />
    </v-navigation-drawer>

    <!-- :permanent="drawer"  -->
    <!-- Menú lateral izquierdo -->
    <!-- :width="anchodrawer" -->
    <v-navigation-drawer v-model="drawer" class="mx-auto" app dense>
      <!-- Logotipo -->
      <v-img
        :src="logo"
        alt="alt"
        class="ma-1"
        contain
        align="center"
        max-width="300"
        @click="irhome"
      />

      <!-- DAtos empresa -->
      <v-card-text class="primary pa-0 white--text" align="center">
        {{ getdatosUsuario.Nomuser }}
      </v-card-text>

      <v-card-text
        v-if="$store.state.Login.cliente.nomcli"
        class="primary pa-0 white--text"
        align="center"
      >
        {{ $store.state.Login.cliente.nomcli.substr(0, 20) }}
      </v-card-text>

      <v-btn x-small block color="secondary" dark @click="mostrarDraw">
        ampliar barra lateral
      </v-btn>
      <v-text-field
        v-model="articulo"
        dense
        hide-details
        clearable
        label="Buscar producto"
        class="ma-4"
        append-icon="search"
        :loading="loading"
        @keyup.enter="buscarart"
        @keyup.113="buscarart"
        @click:append="buscarart"
        @click:clear="limpiar"
      />

      <!-- PROBAR TABLA -->
      <v-simple-table dense height="450px">
        <template #default>
          <tbody>
            <tr
              v-for="(depto, text) in deptos"
              :key="text"
              color="primary"
              dense
              @click="cargaCat(depto)"
            >
              <td class="primary--text">
                {{ depto.text }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- <v-list single-line dense title>
        <v-list-item
          v-for="(depto, text) in deptos"
          :key="text"
          color="primary"
          dense
          @click="cargaCat(depto)"
        >
          <v-list-item-content>
            <v-list-item-title class="mx-1 caption" dense v-text="depto.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list> -->

      <!-- Boton de salir -->

      <v-list-item v-if="getLogeado == true" @click="salir">
        <v-list-item-action>
          <v-icon>logout</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title> Salir </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
    </v-navigation-drawer>

    <!--MODO PATALLA NORMAL-->
    <!-- class="elevation-0 hidden-md-and-down" -->
    <!-- src="https://picsum.photos/1920/1080?random" -->
    <v-app-bar app clipped-right color="secondary" dark dense>
      <!-- <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template> -->

      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" />

      <v-toolbar-title>
        <span class="hidden-sm-and-down">{{ getdatosUsuario.Email }}</span>
      </v-toolbar-title>

      <v-btn icon :href="whatsapp" target="_blank">
        <v-img img src="@/assets/whatsapp.png" height="30" contain />
      </v-btn>

      <!-- <v-app-bar-title>AZALIE</v-app-bar-title> -->

      <v-spacer />
      <v-col cols="3">
        <!-- BUSCAR -->

        <v-text-field
          v-model="articulo"
          dark
          hide-details
          clearable
          label="Buscar producto"
          dense
          solo-inverted
          append-icon="search"
          class="hidden-sm-and-down"
          :loading="loading"
          hide-selected
          @keyup.enter="buscarart"
          @keyup.113="buscarart"
          @click:append="buscarart"
          @click:clear="limpiar"
        />
      </v-col>

      <v-btn
        v-if="moneda"
        color="secondary"
        class="elevation-0"
        @click="denominacion('P')"
      >
        USD
      </v-btn>

      <v-btn v-else color="secondary" class="elevation-0" @click="denominacion('D')">
        MXN
      </v-btn>

      <!-- Menú para perfil -->
      <v-menu
        v-if="getLogeado == true"
        transition="slide-x-transition"
        bottom
        offset-x
        right
        class="mt-2 elevation-2"
      >
        <template #activator="{ on, attrs }">
          <v-btn class="ma-2 elevation-0" color="secondary" v-bind="attrs" v-on="on">
            Mi Cuenta
            <v-icon>account_circle</v-icon>
          </v-btn>
        </template>

        <!-- la lista de perfil -->
        <v-list dense>
          <v-list-item
            v-for="(usuario, i) in usuarios"
            :key="i"
            :to="usuario[2]"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon v-text="usuario[1]" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="usuario[0]" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- @click.stop="irlogin" -->
      <v-btn
        v-if="getLogeado == false"
        class="ma-2"
        color="secondary"
        :to="{ name: 'login' }"
      >
        Login
      </v-btn>

      <v-btn v-if="getLogeado == true" class="ma-2" icon @click="salir">
        <v-icon>exit_to_app</v-icon>
      </v-btn>

      <!-- icono para abrir el carrito -->
      <v-btn icon class="mt-2" color="white" @click.stop="ver">
        <v-icon dark> shopping_cart </v-icon>
      </v-btn>

      <v-spacer />
      <v-img
        class="ma-1"
        eager
        max-height="50"
        width="50"
        align="center"
        :src="logo"
        contain
        alt="SAIT"
        @click="irhome"
      />

      <template #extension>
        <!-- <v-card justify="center" > -->
        <v-tabs v-model="tab" centered background-color="white" dark>
          <v-tab v-for="item in itemstab" :key="item.tab" @change="manuel(item)">
            <v-card color="transparent" class="text-center">
              <v-row justify="space-around">
                <v-menu open-on-hover bottom offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn color="success" dark small v-bind="attrs" v-on="on">
                      {{ item.tab }}
                    </v-btn>
                  </template>

                  <!-- <v-list
                    dense
                    nav

                    rouded
                   >
                    <v-list-item-group
                      color="primary"

                    >
                      <v-list-item dense
                        v-for="(menus, index) in item.categos"
                        :key="index"
                        @click="ir(menus)"
                      >
                        <v-list-item-title>{{ menus.Nomcat }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list> -->

                  <v-simple-table dense height="450px">
                    <template #default>
                      <tbody>
                        <tr
                          v-for="(menus, index) in item.categos"
                          :key="index"
                          @click="ir(menus)"
                        >
                          <td class="primary--text">
                            {{ menus.Nomcat }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-menu>
              </v-row>
            </v-card>
          </v-tab>
        </v-tabs>

        <!-- <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="item in itemstab"
              :key="item.tab"
            >
            </v-tab-item>

          </v-tabs-items> -->
        <!-- </v-card> -->
      </template>

      <!-- <template v-slot:extension>
          <v-tabs  centered
            v-model="tab"
            background-color="primary"
            dark
          >
            <v-tab
              v-for="item in itemstab"
              :key="item.tab"
               @change="manuel(item)"
            >
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="item in itemstab"
              :key="item.tab"
            >
            </v-tab-item>
          </v-tabs-items>
      </template>  -->

      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        height="6"
        striped
        bottom
        color="secondary"
      />
    </v-app-bar>

    <v-main>
      <v-container fluid class="ma-1">
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
        <snackbar />
      </v-container>
    </v-main>

    <!--     <v-main class="pt-16">
      <v-card class="elevation-10" height="100%">
        <v-container fluid class="ma-1">
          <v-slide-y-transition mode="out-in">
            <router-view/>
          </v-slide-y-transition>
        </v-container>
      </v-card>

    </v-main> -->

    <v-bottom-navigation
      id="scrolling-techniques-3"
      class="d d-sm d-md-none primary"
      dense
      widht="100%"
      dark
    >
      <v-toolbar dense floating>
        <v-text-field
          v-model="articulo"
          flat
          hide-details
          clearable
          label="Buscar producto"
          dark
          solo-inverted
          append-icon="search"
          :loading="loading"
          @keyup.enter="buscarart"
          @keyup.113="buscarart"
          @click:append="buscarart"
          @click:clear="limpiar"
        />
      </v-toolbar>

      <!-- Opción para perfil  -->
      <v-menu transition="slide-x-transition" bottom right class="elevation-0">
        <template #activator="{ on }">
          <!-- color="primary" -->
          <v-btn class="elevation-0" dark v-on="on">
            <v-icon>account_box</v-icon>
          </v-btn>
        </template>

        <!-- la lista de perfil -->
        <v-list dense>
          <v-list-item v-for="(usuario, i) in usuarios" :key="i" :to="usuario[2]">
            <!-- color="primary" -->
            <v-list-item-icon>
              <v-icon v-text="usuario[1]" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="usuario[0]" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Iniciar sesión -->
      <!-- SALIR -->
      <!--  <v-menu
        v-if="getLogeado == true"
        transition="slide-x-transition"
        bottom
        right
        class="elevation-0"
        >
        <template v-slot:activator="{ on }">
          <v-btn
            class="elevation-0"
            v-on="on"
            @click="salir"
          >
            <v-icon>exit_to_app</v-icon>Salir
          </v-btn>
        </template>
      </v-menu> -->
    </v-bottom-navigation>

    <v-snackbar v-model="snackbar" :timeout="3000" top color="info" dark>
      {{ msg }}
      <v-btn color="white" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>

    <!--  <v-footer app inset  color="white lighten-2" align="center">
      <v-spacer></v-spacer>
      <div class="black--text"><h5>Sait Tienda en Línea</h5></div>
      <v-spacer></v-spacer>
    </v-footer> -->
  </v-app>
</template>

<script>
// import axios from 'axios'
import Caja from "@/views/Caja.vue";
import store from "@/store";
import carrito from "@/modules/Carrito/carrito";
import snackbar from "@/components/Shared/Snackbar";

//PAYPAL
import { loadScript } from "@paypal/paypal-js";

loadScript({
  "client-id":
    "AT6X3rj0CjV9woaDgzeZWttL_gfNp_g9zqqEExzdGLaf2Smgvv9QQKnmGLQA8zbfDgIvochmuc5qNOt0",
  currency: "MXN",
  components: "buttons,marks,messages",
  locale: "es_MX",
})
  .then((paypal) => {
    // start to use the PayPal JS SDK script
    // console.log("PAYPAL ", paypal);
    var paypal = paypal;
  })
  .catch((err) => {
    console.error("failed to load the PayPal JS SDK script", err);
  });

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Caja,
    snackbar,
  },

  metaInfo() {
    return {
      // title: this.title,
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.title,
      // all titles will be injected into this template
      titleTemplate: "%s | " + `${this.marca}`,
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: `${this.description}`,
        },
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        // { property: 'og:title', content: "Redleaf - Build your network in your field of interest", template: chunk => `${chunk} - My page`,
        // vmid: 'og:title'},
        { property: "og:site_name", content: "Sait Tienda en Línea" },
        { property: "og:type", content: "website" },

        // { property: 'og:image', content: 'https://saiterp.com/b1.png'},
        { property: "og:image", content: this.imagenprin },
        { property: "og:image:type", content: "image/jpeg" },
        { property: "og:image:width", content: "400" },
        { property: "og:image:height", content: "300" },
        {
          property: "og:image:alt",
          content: "La Solución en ecommerce: Sait Tienda en línea",
        },

        { name: "robots", content: "index,follow" },
      ],
    };
  },

  // props: {
  //   source: String
  // },
  data: () => ({
    anchodrawer: "auto",

    title: "Llegó el momento de organizar tu empresa",
    description: undefined,
    marca: "",

    selectedItem: 1,
    tab: null,
    itemstab: [],
    tipotienda: "Normal",
    logo: "",

    whatsapp: "",

    snackbar: false,
    msg: "",
    logeado: false,
    items: [
      {
        icon: "style",
        text: "DEPARTAMENTOS",
        model: false,
        children: [],
      },
    ],

    loading: false,
    drawer: true,
    drawerRight: false,
    carrito: "false",
    esAzalie: true,
    opciones: [
      // ['Mi Carrito'    , 'shopping_cart', '/carrito'     ],
      ["Contacto", "local_phone", "/contacto"],
    ],

    usuarios: [
      ["Mi perfil", "person", "/miperfil"],
      ["Datos fiscales", "receipt", "/datosfis"],
      ["Direcciones de Envío", "time_to_leave", "/envios"],
      ["Mis pedidos", "shopping_basket", "/cotizaciones"],
      ["Seguimiento a Pedidos", "mdi-truck-delivery", "/segped"],
    ],

    articulo: "",
    cliente: "",
    moneda: true,
    vendedor: false,
    deptos: [],

    usarInsignias: false,
    preciosnetos: false,
  }),

  computed: {
    ...mapGetters("config", ["getConfig"]),
    ...mapGetters("calculoEnvios", ["getEnvios"]),
    ...mapGetters("tipoemp", ["getTipoemp"]),

    ...mapGetters("carrito", ["getCarritoVisible", "getTC"]),
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getInfoVend",
      "getModo",
      "getCliente",
      "getClientes",
      "getLogeado",
      "getCIA",
    ]),

    logueado() {
      return this.$store.state.usuario;
    },
  },

  created() {
    // this.cargarPaypal();
    this.title = process.env.VUE_APP_TITLE;
    this.description = process.env.VUE_APP_DESCRIPTION;
    this.imagenprin = process.env.VUE_APP_IMAGEN_PRIN;
    this.marca = process.env.VUE_APP_MARCA;

    this.loading = true;
    this.traerTema(2);

    // console.log(this.title)
    // console.log("title", process.env.VUE_APP_TITLE)
    this.$http.get("api/v1/logos.list").then(
      (response) => {
        this.logo = response.body.Logourl;
        this.setlogourl(this.logo);
      },
      (error) => {
        console.log(error);
      }
    );

    this.$http.get("api/v1/sigfolios.list").then(
      (response) => {
        console.log("sigfolios", response);

        this.precioutil = response.data.Precioutil;
        this.preciosnetos = response.data.preciosnetos;
        this.usaexist = response.data.usaexist;

        let usacarac = false;
        if (response.data.usacarac == "1") {
          usacarac = true;
        }

        let usaexist = false;
        if (response.data.usaexist == "1") {
          usacarac = true;
        }

        const config = {
          precioutil: response.data.Precioutil,
          preciosnetos: response.data.preciosnetos,
          usacarac: usacarac,
          usaexist: usaexist,
        };

        if (this.preciosnetos == "1") {
          this.usarInsignias = true;
        }

        this.setConfig(config);
      },
      (error) => {
        console.log(error);
      }
    );

    // this.precioutil= '4'
    this.moneda = false;

    this.traerTipotienda();
    this.traerTC();
    this.infoCIA();
    this.init();
  },

  methods: {
    ...mapActions("tema", ["traerTema", "setlogourl"]),
    ...mapActions("carrito", [
      "verCarrito",
      "actualizaCarrito",
      "carritoadd",
      "traerTC",
      "limpiarCarrito",
    ]),
    ...mapActions("articulos", [
      "traerArticulosxLinea",
      "bucarArt",
      "traerPromos",
      "verArt",
    ]),
    ...mapActions("Login", ["salirLogin", "infoCIA"]),
    ...mapActions("config", ["setConfig"]),
    ...mapActions("calculoEnvios", ["setEnvios"]),
    ...mapActions("tipoemp", ["setTipoemp"]),

    // async cargarPaypal() {
    //   let paypal;
    //   try {
    //     paypal = await loadScript({
    //       clientId:
    //         "AT6X3rj0CjV9woaDgzeZWttL_gfNp_g9zqqEExzdGLaf2Smgvv9QQKnmGLQA8zbfDgIvochmuc5qNOt0",
    //       currency: "MXN",
    //       components: "buttons,marks,messages",
    //       locale: "es_MX",
    //     });
    //   } catch (error) {
    //     console.error("failed to load the PayPal JS SDK script", error);
    //   }
    // },

    manuel(itemtabsel) {
      // console.log("manuel",itemtabsel)
      this.$router
        .push({
          name: "categoria",
          params: {
            // text: itemtabsel.Nompdep,
            categoria: itemtabsel.Valdep,
            id: itemtabsel.Valdep,
          },
        })
        .catch((err) => {
          console.log(err);
        });
    },

    init() {
      this.cargarTablaEnvios();
      this.cargarTablaTipoemp();

      this.$http
        .get("api/v1/redessoc")
        .then((respuesta) => {
          // console.log("respuesta", respuesta)
          this.facebook = respuesta.body.Facebook;
          this.youtube = respuesta.body.Youtube;
          this.twitter = respuesta.body.Twitter;
          this.linkedin = respuesta.body.Linkedin;
          this.whatsapp = respuesta.body.Whatsapp;
        })
        .catch((error) => {
          console.log(error);
        });

      // console.log("init Precioutil", this.$store.state.precioutil)
      this.precioutil = this.$store.state.precioutil;

      if (this.getCliente != "") {
        this.precioutil = this.getCliente.precioutil;

        // Validar si es cleinte del vendedor
      } else if (this.getClientes != "") {
        this.precioutil = this.getCliente.precioutil;
      }

      this.traerTC()
        .then((response) => {
          this.overlay = false;
          var payload = {
            divisa: this.$store.state.tipomoneda,
            tc: this.getTC,
            precioutil: this.precioutil,
          };

          if (this.$store.state.tipomoneda == "D") {
            this.moneda = true;
          } else {
            this.moneda = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    denominacion(val) {
      // DIVISA. LLAMA A LAS COSAS POR SU NOMBRE CORRECTO.
      this.$store.state.tipomoneda = val;
      if (val == "P") {
        this.moneda = false;
      } else {
        this.moneda = true;
      }

      if (this.getCliente != "") {
        this.precioutil = this.getCliente.precioutil;
        // Validar si es cleinte del vendedor
      } else if (this.getClientes != "") {
        this.precioutil = this.getClientes.precioutil;
      }

      var payloadall = { divisa: val, tc: this.getTC };
      this.actualizaCarrito(payloadall);
      this.traerPromos(payloadall);

      const numlin = this.$route.params.linea;
      var payload = {
        divisa: val,
        numlin: numlin,
        tc: this.getTC,
        precioutil: this.precioutil,
      };

      if (numlin == undefined) {
      } else {
        this.traerArticulosxLinea(payload);
      }

      // console.log(this.$route.params.numart)
      if (this.$route.params.numart) {
        const numart = this.$route.params.numart;
        var payload = {
          numart: numart,
          divisa: this.$store.state.tipomoneda,
          tc: this.getTC,
        };
        this.verArt(payload);
      }
    },

    ver() {
      this.$store.state.carrito.carritovisible = !this.$store.state.carrito
        .carritovisible;
      this.drawerRight = this.$store.state.carrito.carritovisible;
    },

    traerDeptos() {
      // api/v1/deptos.activos.list
      this.$http
        .get("api/v1/deptosycategos")
        .then((response) => {
          // Cargar solo Deptos Activos
          // console.log("deptos", response.data)
          this.itemstab = response.data;
          // response.body.forEach((element) => {
          //   // console.log(element)
          //   this.items[0].children.push({text: element.Nomdep.toUpperCase(), valdep:  element.Valdep })
          //    this.deptos.push({ text: element.Nomdep, valdep:  element.Valdep })
          // })
          this.itemstab.forEach((element) => {
            element.tab = element.Nomdep;
            element.content = element.Id;
            element.categos = element.Categoria;
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    traerLineas() {
      // this.items[0].children = []

      if (this.esAzalie == true) {
        this.$http
          .get("api/v1/lineas.activas.list.xnombre")
          .then((response) => {
            // Cargar solo Deptos Activos
            // console.log("lineas", response.body)
            response.body.forEach((element) => {
              // console.log(element);
              this.items[0].children.push({
                text: element.Nomlin.toUpperCase(),
                valdep: element.Numlin,
              });
              this.deptos.push({
                text: element.Nomlin.toUpperCase(),
                valdep: element.Numlin.trim(),
              });
            });
            // console.log(this.items)
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // deptos.list
        this.$http
          .get("api/v1/lineas.activas.list")
          .then((response) => {
            // Cargar solo Deptos Activos
            // console.log("lineas", response.body)
            response.body.forEach((element) => {
              // console.log(element);
              this.items[0].children.push({
                text: element.Nomlin.toUpperCase(),
                valdep: element.Numlin,
              });
              this.deptos.push({
                text: element.Nomlin.toUpperCase(),
                valdep: element.Numlin.trim(),
              });
            });
            // console.log(this.items)
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    traerTipotienda() {
      // console.log("Tipotienda", this.tipotienda)

      this.$http
        .get("api/v1/tipotienda")
        .then((response) => {
          // console.log("tipomoneda response", response)
          this.tipotienda = response.body.Tipotienda;
          // console.log("this.tipotienda",this.tipotienda)

          // TIPO DE TIENDA
          if (this.tipotienda == "Normal") {
            // console.log("Normal")
            // this.traerDeptos()
            this.traerLineas();
          } else {
            // console.log("Compacta, ESTA ES LA QUE FUNCIONA BIEN PARA TODOS.");
            this.traerLineas();
            this.traerDeptos();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cargaCat(value) {
      // console.log("cargCat: ",value)
      this.reducirDraw();
      // Tienda Normal
      if (this.tipotienda == "Normal") {
        this.$router
          .push({
            name: "categoria",
            params: {
              text: value.text,
              categoria: value.valdep,
              id: value.valdep,
            },
          })
          .catch((err) => {
            console.log(err);
          });

        // COMPACTA
      } else {
        // Llamar a vista Insignias
        if (this.usarInsignias == true) {
          this.$router
            .push({
              name: "artlineainsignia",
              params: {
                linea: value.valdep,
                text: value.text,
              },
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$router
            .push({
              name: "artlinea",
              params: {
                linea: value.valdep,
                text: value.text,
              },
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    ir(menuSeleccionado) {
      // console.log("ir a catego", menuSeleccionado);

      // this.$router.push({
      //   name: 'artlinea.catego' ,
      //   params: {  categoria: menuSeleccionado.Numcat.trim()
      //   }
      // }).catch(err => {console.log(err)})

      // Llamar a vista Insignias

      if (this.usarInsignias == true) {
        this.$router
          .push("/artcategoinsignia/" + menuSeleccionado.Numcat.trim())
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$router
          .push({
            name: "artlinea.catego",
            params: { categoria: menuSeleccionado.Numcat.trim() },
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    salir() {
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
      // Limpiamos el carrito
      var value = [];
      this.limpiarCarrito(value);

      this.$store.dispatch("salir");
      this.salirLogin();

      // Traer el tipo de tienda
      this.traerTipotienda();
    },

    buscarart() {
      // Validar si es cliente del usuario
      if (this.getCliente != "") {
        this.precioutil = this.getCliente.precioutil;
        // Validar si es cleinte del vendedor
      }
      // }else if(this.getClientes != ''){
      //   this.precioutil = this.getClientes.precioutil
      // }

      this.traerTC()
        .then((response) => {
          var payload = {
            BuscAart: this.articulo,
            divisa: this.$store.state.tipomoneda,
            tc: this.getTC,
            precioutil: this.precioutil,
          };

          // Hacer Búsqueda y mnadar resulado al state.articulo
          this.bucarArt(payload);

          // Llamar a vista Insignias
          // if (this.usarInsignias == true){
          //   this.$router.push( {name: 'artlineainsignia'
          //   }).catch(err => {console.log(err)})

          // } else {
          this.$router.push({ name: "catart" }).catch((err) => {});

          // }

          // this.articulo = ''
        })
        .catch((err) => {
          console.log(err);
        });
    },

    limpiar() {
      this.buscando = "";
    },

    irlogin() {
      console.log("login");
      this.$router.push({ name: "login" }).catch((err) => {
        console.log(err);
      });
    },

    irhome() {
      this.getModo;
      if (this.getModo == "vendedor") {
        this.$router.push({ name: "homevendedor" }).catch((err) => {});
      } else {
        this.$router.push({ name: "home" }).catch((err) => {});
      }
    },

    cargarTablaEnvios() {
      this.loading = true;
      // console.log("tabla de envios")
      this.$http
        .get("api/v1/envios.list")
        .then((response) => {
          // console.log("response",response)

          const envios = response.data;
          // this.longitud= this.Envios.length
          // console.log(envios)
          if (envios > null) {
            envios.forEach((element) => {
              element.Preciobase =
                element.Costobase + (element.Monto * element.Pjeventa) / 100;
            });

            this.setEnvios(envios);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    cargarTablaTipoemp() {
      this.loading = true;
      // console.log("tabla de envios")
      this.$http
        .get("api/v1/tipoemp.list")
        .then((response) => {
          // console.log("response")
          const tipoemp = response.data;
          // this.longitud= this.Envios.length
          // console.log(this.Envios)
          // tipoemp.forEach(element =>{
          //   element.Preciobase = element.Costobase + (element.Monto * element.Pjeventa/100)
          // })
          this.setTipoemp(tipoemp);
          // console.log(this.getTipoemp)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    mostrarDraw() {
      this.anchodrawer = "auto";
    },
    reducirDraw() {
      this.anchodrawer = "300";
    },
  },
};
</script>

<style>
.max-v-list-height {
  height: auto;
  overflow-y: auto;
}
</style>
