<template>
  <v-container
    class="elevation-12 ma-1 pa-1"
    grid-list-xl
    fill-height
  >
    <v-row>
      <v-col cols="12">
        <v-toolbar
          dense
          class="text-h4"
          dark
          color="primary"
        >
          <v-toolbar-title>Revisión final de pedido </v-toolbar-title>

          <!-- <v-img contain :src="logo" alt="SAIT"  height="175"/> -->
          <v-spacer />
          <v-btn
            dark
            :loading="loading"
            color="info"
            @click="order()"
          >
            Pasar a Caja
          </v-btn>
        </v-toolbar>
      </v-col>

      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
      >
        <v-card class="elevation-2">
          <v-card-text>
            <v-subheader> Datos del CLIENTE</v-subheader>
            <div class="text-xs-left">
              <strong> RFC {{ CargaCliente.Rfc }}</strong>
            </div>
            <div class="text-xs-left">
              {{ CargaCliente.nomcli }}
            </div>
            <div class="text-xs-left">
              {{ direccion }}
            </div>
            <div class="text-xs-left">
              {{ CargaCliente.telefono }}
            </div>
            <div class="text-xs-left">
              {{ CargaCliente.email1 }}
            </div>
          </v-card-text>
        </v-card>

        <!-- <v-card>
          <v-card-text>
            <h2 class="red--text">
              <strong>Registrarse como cliente</strong>
            </h2>
          </v-card-text>
        </v-card> -->
      </v-col>

      <!-- {{ direntrega }} -->
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
      >
        <v-card class="elevation-2">
          <v-card-text>
            <v-radio-group
              v-model="radios"
              :mandatory="false"
              row
            >
              <v-radio
                label="Recoger en tienda"
                value="1"
                color="primary"
              />
              <v-radio
                label="Envío a domicilio"
                value="2"
                color="primary"
              />
            </v-radio-group>
            <!-- @change="seldire(` ${select.idweb}`)" -->
            <v-select
              v-if="radios == 2"
              v-model="select"
              :hint="`  ${select.Calle} ${select.Numext} ${select.colonia} ${select.Ciudad}
              ${select.Estado} CP ${select.Cp} Tel. ${select.telefono} `"
              :items="direcciones"
              item-text="nombre"
              item-value="idweb"
              label="Dirección de envio"
              prepend-icon="house"
              persistent-hint
              return-object
              @change="cambiodirenvio"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify-center>
      <v-snackbar
        v-model="snackbar"
        :timeout="8000"
        top
        color="orange"
        dark
      >
        {{ msg }}
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-col cols="12">
        <v-card class="elevation-10">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-card class="elevation-0">
                  <v-card-text class="py-0">
                    <div class="text-xs-left">
                      <strong>{{ getCIA.nomcia }}</strong>
                    </div>
                    <div class="text-xs-left">
                      <strong>{{ getCIA.rfccia }}</strong>
                    </div>
                    <!-- <div class="text-xs-left ">{{ getCIA.regimen }}</div> -->

                    <div class="text-xs-left">
                      {{ getCIA.calle + " " + getCIA.numext + " " + getCIA.colonia }}
                    </div>
                    <div class="text-xs-left">
                      {{ getCIA.ciudad + ", " + getCIA.estado + " CP: " + getCIA.cp }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-card class="elevation-0">
                  <v-card-text class="py-0">
                    Puedes procesar tu pedido iniciando el proceso de pago. Pedido mínimo
                    de $ 500.00
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Datos del cliente -->

              <!-- Datos generales -->
              <v-col
                cols="12"
                md="6"
              >
                <v-card class="elevation-0">
                  <v-card-text>
                    <!-- <div class="text-xs-left "><strong>Folio: </strong>WMP03</div> -->
                    <div class="text-xs-left">
                      <strong>Fecha: </strong>{{ fecha }}
                    </div>
                    <!-- <div class="text-xs-left"><strong>Tipo Doc: </strong>Cotización</div> -->
                    <div class="text-xs-left">
                      <strong>Divisa: </strong>MXN
                    </div>
                  </v-card-text>
                  <v-img
                    class="ma-2"
                    contain
                    :src="getLogourl"
                    max-height="100"
                    max-width="200"
                  />
                </v-card>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-row justify-end>
                  <v-col cols="12">
                    <v-card class="elevation-0">
                      <v-row>
                        <v-col
                          cols="12"
                          md="5"
                        >
                          <v-card class="transparent elevation-0">
                            <v-card-text>
                              <div class="text-xs-left">
                                <h3><strong>Importe:</strong></h3>
                              </div>
                              <div class="text-xs-left">
                                <h3><strong>Descuento:</strong></h3>
                              </div>
                              <div
                                v-if="mostrarPreciosNetos"
                                class="text-xs-left"
                              >
                                <h3><strong>Subtotal:</strong></h3>
                              </div>
                              <div
                                v-if="mostrarPreciosNetos"
                                class="text-xs-left"
                              >
                                <h3><strong>Iva:</strong></h3>
                              </div>
                              <div class="text-xs-left">
                                <h3><strong>Total:</strong></h3>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col
                          cols="12"
                          md="5"
                        >
                          <v-card class="transparent elevation-0">
                            <v-card-text align="right">
                              <div class="text-xs-left">
                                <h3>
                                  <strong>{{ importedoc }}</strong>
                                </h3>
                              </div>
                              <div class="text-xs-left">
                                <h3>
                                  <strong>{{ descuentodoc }}</strong>
                                </h3>
                              </div>

                              <div
                                v-if="mostrarPreciosNetos"
                                class="text-xs-left"
                              >
                                <h3>
                                  <strong>{{ subtotal }}</strong>
                                </h3>
                              </div>
                              <div
                                v-if="mostrarPreciosNetos"
                                class="text-xs-left"
                              >
                                <h3>
                                  <strong>{{ iva }}</strong>
                                </h3>
                              </div>
                              <div class="text-xs-left">
                                <h3>
                                  <strong>{{ total }}</strong>
                                </h3>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        xs="12"
      >
        <v-data-table
          :headers="headers"
          :items="getCarrito"
          fixed-header
          class="elevation-3 text-xs-center"
          hide-default-footer
        >
          <template #[`item.actions`]="{ item }">
            <th>
              <v-icon
                color="success"
                @click="add(item)"
              >
                add
              </v-icon>
              <v-icon
                color="red"
                @click="remove(item)"
              >
                remove
              </v-icon>
            </th>
          </template>

          <template #[`item.img`]="{ item }">
            <v-img
              v-if="item.img == ''"
              contain
              :src="getLogourl"
              max-height="60"
              max-width="100"
            />
            <v-img
              :src="item.img"
              contain
              max-height="60"
              max-width="100"
            />
          </template>

          <template #no-data>
            <v-alert
              type="error"
              :value="true"
              class="mt-2"
            >
              No hay articulos en tu carrito
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-toolbar
      class="text-h4"
      dark
      color="primary"
    >
      Revisa tu pedido
      <!-- <v-img contain :src="logo" alt="SAIT"  height="175"/> -->
      <v-spacer />
      <v-btn
        dark
        :enabled="loading"
        color="info darken-3"
        @click.stop="order"
      >
        Pasar a Caja
      </v-btn>
    </v-toolbar>

    <!-- COMPROBANTE -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import tempCarrito from "@/mixins/tempCarrito.js";
// var accounting = require("accounting");
var base64 = require("base-64");

export default {
  mixins: [tempCarrito],

  data() {
    return {
      mostrarPreciosNetos: false,
      numclisuc: "",
      // direntrega: [],
      direcciones: [],
      loading: false,
      select: {
        nombre: "",
        idweb: "",
        encargado: "",
        Calle: "",
        Numext: "",
        colonia: "",
        Ciudad: "",
        Estado: "",
        Cp: "",
        telefono: "",
        numclisuc: "",
      },
      logo: "",
      // copias
      email1: "",
      email2: "",

      dialog: false,
      acceso: false,
      tipoDoc: "",
      moneda: "",
      divisa: "",

      direccion: "",
      CargaCliente: [],
      msg: "",
      snackbar: false,

      importedoc: 0.0,
      descuentodoc: 0.0,
      total: 0.0,
      iva: 0.0,
      subtotal: 0.0,

      Movim: [],
      radios: "2",

      fecha: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "", align: "center", value: "actions", sortable: false },
        { text: "Clave", align: "left", value: "clave", sortable: false },
        { text: "Foto", align: "center", value: "img", sortable: false },
        {
          text: "Descripción",
          align: "left",
          value: "details",
          sortable: false,
        },
        { text: "Cant", align: "center", value: "cantidad", sortable: false },
        { text: "Und", align: "center", value: "unidad", sortable: false },
        { text: "Precio", align: "center", value: "precio", sortable: false },
        {
          text: "Descuento",
          align: "center",
          value: "descuento",
          sortable: false,
        },
        { text: "Neto", align: "center", value: "total", sortable: false },
        // { text: 'Descuento'	  ,align: 'center', value: 'descuento',   sortable: false },
        // { text: 'Total'			  ,align: 'center', value: 'total',     sortable: false }
      ],
    };
  },

  computed: {
    ...mapGetters("tema", ["getLogourl"]),
    ...mapGetters("carrito", ["getCarrito", "getTC"]),
    ...mapGetters("Login", [
      "getdatosUsuario",
      "getClientes",
      "getCliente",
      "getCIA",
      "getInfoVend",
      "getModo",
    ]),
    ...mapState("carrito", ["carrito"]),
    ...mapGetters("direccion", ["getDirecciones", "getDireccion"]),
    ...mapGetters("config", ["getConfig"]),
    ...mapGetters("direnvio", ["getDirecciones", "getDirenvio"]),
  },
  watch: {
    getCarrito() {
      this.crearArreglo();
    },

    radios() {
      if (this.radios == 2) {
        this.dialog = true;
      }
    },
    getConfig() {
      if (this.getConfig.preciosnetos == "1") {
        this.mostrarPreciosNetos = false;
      }
      if (this.getConfig.preciosnetos == "0") {
        this.mostrarPreciosNetos = true;
      }
    },
  },

  created() {
    this.crearArreglo();
    // console.log("getCliente", this.getClientes)
    this.CargaCliente = this.getClientes;

    this.logo = this.getLogourl;

    this.$http
      .get("api/v1/email.list")
      .then((response) => {
        // console.log(response)
        this.email1 = response.body.Email1;
        this.email2 = response.body.Email2;
      })
      .catch((err) => {
        console.log(err);
      });

    // Vaidamos si ya esta logeado
    if (this.getdatosUsuario == "") {
      this.msg = "Aún no eres usuario";
      this.snackbar = true;
      this.$router.push({ name: "login" }).catch((err) => {
        console.log(err);
      });
      return;
    }

    // console.log(this.getdatosUsuario.numcli)
    // validamos si es un usuario normal
    if (this.getCliente == "" && this.getClientes == undefined) {
      this.msg = "Agrega tus datos fiscales en perfil/datos fiscales";
      this.snackbar = true;
      // this.$router.push({name:'usuarioevent'})
      // this.crearArreglo()
      return;
    }

    if (this.getdatosUsuario.numcli != undefined) {
      this.direcciones = this.getDirecciones;
      this.select = this.getDirenvio;
    }
    // if (this.getdatosUsuario.numcli != undefined) {
    //   const numcli = this.getdatosUsuario.numcli;
    //   let direcciones = [];
    //   const dircliente = [];
    //   this.$http
    //     .get("auth/api/v1/succlibynumcli/" + numcli)
    //     .then((response) => {
    //       // console.log("response suc by numcli", response)
    //       // console.log('traerDirecciones js', response.data.length)

    //       if (response.data != null) {
    //         // console.log(response.data)
    //         direcciones = response.data;

    //         direcciones.forEach((element) => {
    //           // console.log('element', element)
    //           dircliente.push(element);
    //         });

    //         console.log("dirclientes", dircliente);
    //         this.dircliente = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    //     .finally(() => (this.loading = false));
    // }

    // console.log("Direcciones de Envio getters",this.getDirecciones)

    this.direccion =
      this.CargaCliente.Calle +
      ", " +
      this.CargaCliente.Numint +
      ", " +
      this.CargaCliente.Numext +
      ", " +
      this.CargaCliente.colonia +
      ", " +
      this.CargaCliente.Ciudad +
      ", " +
      this.CargaCliente.Estado +
      ", " +
      this.CargaCliente.Cp +
      ", " +
      this.CargaCliente.Pais;

    this.acceso = true;
  },

  methods: {
    ...mapActions("documento", ["addTotales", "addDocumento"]),
    ...mapActions("carrito", ["limpiarCarrito"]),
    ...mapActions("direccion", ["traerDirecciones", "verDireccion"]),
    ...mapActions("direnvio", ["setDirenvio"]),

    pagarPedido() {
      console.log("Pagar Pedido");
    },

    cambiodirenvio() {
      this.setDirenvio(this.select);
    },

    // getNumclisuc(item) {
    //   // console.log('numclisuc selecte', item)
    //   this.numclisuc = item.numclisuc;
    // },

    order() {
      //////////////////////////GET TOTALES
      if (this.total < 500) {
        console.log("minimo 500");
        this.msg = "Pedido mínimo de $ 500.00";
        this.snackbar = true;
        return;
      }

      if (this.select.nombresuc === "") {
        console.log("FALTA LA DIRECCION DE ENVO");
        return;
      }
      this.loading = true;

      var me = this;
      if (this.getdatosUsuario == "") {
        this.msg = "Necesitas registrarte";
        this.snackbar = true;
        setTimeout(function () {
          me.$router.push({ name: "registro" });
        }, 2000);
        return;
      }

      // vemos que tenga acceso a cotizar
      if (this.acceso == false) {
        this.snackbar = true;
        return;
      }

      this.tipoDoc = " Q";
      // verificamos si es un cliente
      var numcli = "";
      if (this.getModo == "vendedor") {
        numcli = this.CargaCliente.numcli.padStart(5);
      } else {
        numcli = this.getdatosUsuario.numcli;
      }

      // vemos el tipo de moneda
      if (this.$store.state.tipomoneda == "P") {
        this.moneda = "MXN";
        this.divisa = "P";
      } else {
        this.moneda = "USD";
        this.divisa = "D";
      }

      this.getCarrito;
      // vemos que el carrito tenga articulos
      if (this.getCarrito.length > 0) {
        var accounting = require("accounting");
        if (this.getdatosUsuario == "") {
          this.msg = "Inicia sesión para grabar tus datos";
          this.snackbar = true;
        } else {
          var movin_array = [];
          var i = 1;
          this.getCarrito.forEach((element) => {
            movin_array.push({
              tipodoc: this.tipoDoc,
              numdoc: "",
              numpar: i.toString(),
              numart: element.clave,
              precio: accounting.unformat(element.precio).toString(),
              cant: element.cantidad,
              pend: element.cantidad,
              impuesto1: element.impuesto,
              impuesto2: 0,
              unidad: element.unidad,
              docart: "",
              obs: "",
              pjedesc: element.pjedesc,
              peso: 0.0,
            });
            i = i + 1;
          });

          var numvend = "";
          if (this.getInfoVend != "") {
            numvend = this.getInfoVend.Numvend;
          } else {
            numvend = "";
          }

          // console.log("idweb",this.select.idweb)
          // console.log("numclisuc",this.select.numclisuc)

          var docum = {
            fecha: new Date().toISOString().substr(0, 10),
            fechapago: new Date().toISOString().substr(0, 10),

            numcli: numcli,
            numuser: "",
            tc: this.getTC,

            numalm: "3",
            divisa: this.divisa,
            formapago: "1",
            refer: "",

            numvend: numvend,
            tipodoc: this.tipoDoc,
            numdoc: "",

            importe: parseFloat(accounting.unformat(this.importedoc)),
            descuento: parseFloat(accounting.unformat(this.descuentodoc)),
            impuesto1: parseFloat(accounting.unformat(this.iva)),
            impuesto2: 0.0,

            movim: movin_array,
            obs: "",
            hora: this.hora(),
            numclisuc: this.select.numclisuc,
            direnvio:
              this.select.Calle +
              " " +
              this.select.Numext +
              " " +
              this.select.colonia +
              " " +
              this.select.Ciudad +
              " " +
              this.select.Estado +
              " " +
              this.select.Cp +
              " " +
              this.select.telefono,
          };

          // console.log("docum ", docum)
          this.$http
            .post("api/v1/order.add", docum)
            .then((response) => {
              // Avisamos que se proceso correctamente la cotización
              // this.msg = 'Cotización procesada correctamente'
              // this.snackbar = true
              // Limpiar carrito
              var value = [];

              // console.log(response)
              // console.log('ADD DOCUMENTO  AQUI...... fijar iddocum', response)
              docum.iddocum = response.data[0].iddocum;
              // console.log('docum setDocumento', docum)
              this.addDocumento(docum);

              this.limpiarCarrito(value);

              // QUE PARAMETROS OCUPA LA FUNCION ENVIAR CORREOS
              //this.enviarCorreo(response.body)
              const iddocum = base64.encode(response.body[0].iddocum);
              // console.log(response.body)
              const iddocumcarrito = response.body[0].iddocum;
              const doc = { doc: iddocum };
              this.$router.push({
                name: "documento",
                params: { info: doc, iddocum: iddocumcarrito },
              });

              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      } else {
        this.snackbar = true;
        this.msg = "Ve a aticulos y agrega unos a tu carrito";
      }

      this.saitAddCot(docum);
    },

    saitAddCot(docum) {
      // GRabar Cotizacion en Sait.
      // console.log(docum)
      /// //
      var items = [];
      var i = 1;

      docum.movim.forEach((element) => {
        items.push({
          tipodoc: " Q",
          numdoc: "A1",
          numpar: i,
          numart: element.numart,
          desc: "Manuel",
          pend: element.cant,
          pjedesc: element.pjedesc,
          cant: element.cant,
          unidad: element.unidad,
          impuesto1: element.impuesto1,
          impuesto2: 0,
          precio: parseFloat(element.precio),
          obs: "",
        });
        i = i + 1;
      });

      /// /
      // console.log(docum)
      let Pedidodoc = {
        tipodoc: docum.tipodoc,
        numdoc: "Q20",
        numuser: "",
        nunvend: "",

        fecha: docum.fecha,
        fentrega: docum.fecha,
        fechacapt: docum.fecha,

        divisa: docum.divisa,

        hora: docum.hora,
        hentrega: "",

        status: 1,
        formapago: "1",
        numalm: docum.numalm,
        impuesto1: docum.impuesto1,
        impuesto2: 0,
        descuento: docum.descuento,
        importe: docum.importe,
        numcli: docum.numcli,
        pjedesc: 0,
        nomcli: "",
        direnvio: docum.direnvio,
        tc: docum.tc,
        obs: docum.obs,
        items: items,
      };

      // console.log("Checar IVA cotizaciones", Pedidodoc)
      // console.log("TokenSait", this.tokenSait)

      // API AXIOS SAIT NUBE
      // var url = process.env.VUE_APP_SAIT_NUBE
      // 		var uri = url + '/api/v1/cotizaciones'

      // 		// console.log(uri)

      // axios.post(uri, Pedidodoc,{ headers: {'Content-Type': 'application/json', 'x-token': this.tokenSait }}).then(response =>  {
      //      // console.log("Sait NUBE add Cot",response.data)

      //    }).catch(error => {console.log(error) })
    },

    hora() {
      var f = new Date();
      var hora = f.getHours();
      var minuto = f.getMinutes();
      var segundo = f.getSeconds();
      if (hora < 10) {
        hora = "0" + f.getHours();
      }
      if (minuto < 10) {
        minuto = "0" + f.getMinutes();
      }
      if (segundo < 10) {
        segundo = "0" + f.getSeconds();
      }
      return hora + ":" + minuto + ":" + segundo;
    },

    add(articulo) {
      // RECORRE EL CARRITO PARA LOACALIZAR LA CLAVE y subir la cantidad.
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        if (this.getCarrito[i].clave == articulo.clave) {
          this.getCarrito[i].cantidad = this.getCarrito[i].cantidad + 1;
        }
      }

      this.crearArreglo();
    },

    remove(articulo) {
      // Recorre le carrito. Y donde encuentra el parametro lo resta. Si es cero lo elimina.
      for (var i = this.getCarrito.length - 1; i >= 0; i--) {
        if (this.getCarrito[i].clave == articulo.clave) {
          this.getCarrito[i].cantidad = this.getCarrito[i].cantidad - 1;

          if (this.getCarrito[i].cantidad == 0) {
            this.getCarrito.splice(i, 1);
          }
        }
      }
      this.crearArreglo();
    },

    crearArreglo() {
      // console.log("crearArreglo")
      this.formarCarrito();
    },

    enviarCorreo(param) {
      this.loading = true;
      var correo = "";
      if (this.getdatosUsuario.numcli) {
        correo = this.getdatosUsuario.Email;
      } else {
        correo = this.CargaCliente.email1;
      }

      if (this.$store.state.tipomoneda == "P") {
        this.moneda = "MXN";
      } else {
        this.moneda = "USD";
      }

      // La respuesta de order.add incluye el iddocum del generado
      const iddocum = base64.encode(param[0].iddocum);
      const url = this.$http.options.root.replace("tienda3", "consultacoti/");
      const payload = {
        email: correo,
        subject: "Cotización " + param[0].numdoc.trim(),

        info1: "Cotización",
        info3: "Se ha solicitado una cotización con el folio: ",
        folio: param[0].numdoc.trim(),

        nombre: this.getdatosUsuario.Nomuser,
        nomuser: this.getdatosUsuario.Nomuser,
        direccion: this.direccion,
        fecha: new Intl.DateTimeFormat("es").format(this.Fecha),
        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,
        rfc: this.CargaCliente.Rfc,
        subtotal: this.subtotal,
        iva: this.iva,
        total: this.total,
        c2a_link: url + iddocum,
        hora: this.hora(),
        logo: this.logo,
        color: this.$vuetify.theme.themes.light.primary,
        email1: this.email1,
        email2: this.email2,
        numdoc: param[0].numdoc.trim(),
        nomcli: this.getCliente.nomcli,
        layout: "pedido.html",
      };
      // Enviar el correo
      // this.$http.post('auth/api/v1/sendcot', payload).then(response => {
      //   	const iddocumcarrito = param[0].iddocum
      //   	const doc = { doc: iddocum }
      //   	this.$router.push({ name: 'documento', params: { info: doc, iddocum: iddocumcarrito } })
      // })

      this.loading = true;
      this.$http
        .post("auth/api/v1/sendmail6", payload)
        .then((response) => {
          // console.log('enviando Correo sendmail6', response)
          this.loading = false;

          const iddocumcarrito = param[0].iddocum;
          const doc = { doc: iddocum };
          this.$router.push({
            name: "documento",
            params: { info: doc, iddocum: iddocumcarrito },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
