import Vue from 'vue'
import Router from 'vue-router'

import CatArt from '@/views/articulos/CatArts.vue'
import VerArt from '@/views/articulos/VerArt.vue'
import VerArtInsignia from '@/views/articulos/VerArtInsignia.vue'

import ArtLinea from '@/views/articulos/ArtLinea.vue'
import ArtLineaInsignia from '@/views/articulos/ArtLineaInsignia.vue'

import Categorias from '@/views/categoria/Categorias.vue'
import Lineas from '@/views/categoria/Lineas.vue'

import Home from '@/views/home/Home.vue'
import HomeVendedor from '@/views/home/HomeVendedor.vue'
// import Prueba from '@/views/Prueba.vue'

import Carrito from '@/views/carrito/Carrito.vue'
import VueJwtDecode from 'vue-jwt-decode'

import store from '@/store'
import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(VueJwtDecode)
Vue.use(Meta)

var router = new Router({
  // process.env.CORDOVA_PLATFORM ? 'hash' :   mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  // process.env.CORDOVA_PLATFORM ? 'hash' :   mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'hash' ,
  // mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  // mode: 'hash' ,
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,

  routes: [

    {
      path: '/procesarpago/:iddocum',
      name: 'procesarpago',
      component: () => import('@/views/pagos/procesarpago'),
      meta: { libre: true }

    },

    { path: '/', name: 'home', component: Home, meta: { libre: true } },
    { path: '/homevendedor', name: 'homevendedor', component: HomeVendedor, meta: { libre: true } },

    {
      path: '/menuprincipal',
      name: 'menuprincipal',
      component: () => import('@/views/home/menuprincipal.vue'),
      meta: { libre: true }
    },

    {
      path: '/manuel',
      name: 'manuel',
      component: () => import('@/views/Login/Manuel.vue'),
      meta: { libre: true }
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/Login.vue'),
      meta: { libre: true }
    },

    {
      path: '/promociones',
      name: 'promociones',
      component: () => import('@/components/Promociones.vue'),
      meta: { libre: true }
    },

    // Funciones especiales
    {
      path: '/registro',
      name: 'registro',
      component: () => import('@/views/Login/Registro.vue'),
      meta: { libre: true }
    },

    {
      path: '/olvidacontra',
      name: 'olvidacontra',
      component: () => import('@/views/Login/OlvidaContra.vue'),
      meta: { libre: true }
    },

    {
      path: '/cambiacontra/:id',
      name: 'cambiacontra',
      component: () => import('@/views/Login/CambiaContra.vue'),
      meta: { libre: true }
    },

    {
      path: '/activarusuario/:id',
      name: 'activarusuario',
      component: () => import('@/views/Login/ActivarUsuario'),
      meta: { libre: true }
    },

    // Usuario eventual
    {
      path: '/usuarioevent',
      name: 'usuarioevent',
      component: () => import('@/views/usuario/UsuarioEventual'),
      meta: { libre: true }
    },

    { path: '/catart', name: 'catart', component: CatArt, meta: { libre: true } },
    { path: '/artlinea/:linea', name: 'artlinea', component: ArtLinea, meta: { libre: true } },

    { path: '/artlineainsignia/:linea', name: 'artlineainsignia', component: ArtLineaInsignia, meta: { libre: true } },

    // ART Catego
    { path: '/artcategoinsignia/:categoria', name: 'artcategoinsignia', component: ArtLineaInsignia, meta: { libre: true } },

    { path: '/artlinea.catego/:categoria', name: 'artlinea.catego', component: ArtLinea, meta: { libre: true } },

    { path: '/verart/:numart', name: 'verart', component: VerArt, meta: { libre: true } },
    { path: '/verartinsignia/:numart', name: 'verartinsignia', component: VerArtInsignia, meta: { libre: true } },

    {
      path: '/relacionados',
      name: 'relacionados',
      component: () => import('@/views/articulos/Relacionados'),
      meta: { libre: true }
    },
    {
      path: '/colores',
      name: 'colores',
      component: () => import('@/views/articulos/colores'),
      meta: { libre: true }
    },

    // CARRITO
    { path: '/carrito', name: 'carrito', component: Carrito, meta: { libre: true } },

    // CONTACTO
    {
      path: '/contacto',
      name: 'contacto',
      component: () => import('@/views/Contacto'),
      meta: { libre: true }
    },

    { path: '/categoria/:id', name: 'categoria', component: Categorias, meta: { libre: true } },
    { path: '/lineas/:id', name: 'lineas', component: Lineas, meta: { libre: true } },

    // Envíos
    {
      path: '/envios',
      name: 'envios',
      component: () => import('@/views/envios/Envios.vue'),
      meta: { acceso: true }
    },
    {
      path: '/adddireccion',
      name: 'adddireccion',
      component: () => import('@/views/envios/AddDireccion.vue'),
      meta: { acceso: true }
    },
    {
      path: '/direccion',
      name: 'direccion',
      component: () => import('@/views/envios/EditDireccion.vue'),
      meta: { acceso: true }
    },

    // COTIZACIONES Y DOCUMENTOS
    {
      path: '/cotizaciones',
      name: 'cotizaciones',
      component: () => import('@/views/cotizaciones/Cotizacion'),
      meta: { acceso: true }
    },

    {
      path: '/documento',
      name: 'documento',
      component: () => import('@/views/cotizaciones/Documento'),
      meta: { acceso: true }
    },

    {
      path: '/consultacoti/:tipodoc',
      name: 'consultacoti',
      component: () => import('@/views/cotizaciones/ConsultaCoti'),
      meta: { libre: true }
    },

    // SEGUIMIENTO A PEDIDOS
    {
      path: '/segped',
      name: 'segped',
      component: () => import('@/views/seguimiento/segped'),
      meta: { libre: true }
    },

    {
      path: '/operaped',
      name: 'operaped',
      component: () => import('@/views/seguimiento/operaped.vue'),
      meta: { acceso: true }
    },
    {
      path: '/pagar1',
      name: 'pagar1',
      component: () => import('@/views/seguimiento/opciones/pagar1.vue'),
      meta: { acceso: true }
    },
    {
      path: '/pagar2',
      name: 'pagar2',
      component: () => import('@/views/seguimiento/opciones/pagar2.vue'),
      meta: { acceso: true }
    },
    {
      path: '/surtir',
      name: 'surtir',
      component: () => import('@/views/seguimiento/opciones/surtir.vue'),
      meta: { acceso: true }
    },

    // {path: '/solcotiza/:id'   , name: 'solcotiza'   , component: ()=> import('@/views/cotizaciones/SolCotiza'),
    //   meta: { acceso: true}},

    {
      path: '/impresion',
      name: 'impresion',
      component: () => import('@/views/pedidos/Impresion'),
      meta: { libre: true }
    },

    // DATOS DE USUARIO
    {
      path: '/miperfil',
      name: 'miperfil',
      component: () => import('@/views/usuario/MiPerfil'),
      meta: { acceso: true }
    },
    {
      path: '/datosfis',
      name: 'datosfis',
      component: () => import('@/views/usuario/Datosfis'),
      meta: { acceso: true }
    },

    // PROCESO DE PAGO
    {
      path: '/tipopago',
      name: 'tipopago',
      component: () => import('@/views/pagos/TipoPago'),
      meta: { acceso: true }
    },

    {
      path: '/validainfo',
      name: 'validainfo',
      component: () => import('@/views/pagos/ValidaInfo'),
      meta: { acceso: true }
    },

    {
      path: '/paypal',
      name: 'paypal',
      component: () => import('@/views/pagos/Paypal'),
      meta: { acceso: true }
    },

    // {
    //   path: '/paypalej',
    //   name: 'paypalej',
    //   component: () => import('@/views/pagos/PaypalEj'),
    //   meta: { acceso: true }
    // },

    {
      path: '/paypalcheck',
      name: 'paypalcheck',
      component: () => import('@/views/pagos/PaypalCheck'),
      meta: { acceso: true }
    },

    
    {
      path: '/openpay',
      name: 'openpay',
      component: () => import('@/views/pagos/Openpay'),
      meta: { acceso: true }
    },

    {
      path: '/fiserv',
      name: 'fiserv',
      component: () => import('@/views/pagos/Fiserv'),
      meta: { acceso: true }
    },

    {
      path: '/finalizarfiserv/:idg',
      name: 'finalizarfiserv',
      component: () => import('@/views/pagos/Finalizarfiserv'),
      meta: { libre: true }
    },


    {
      path: '/notificarurl/:iddocum',
      name: 'notificarurl',
      component: () => import('@/views/pagos/notificar'),
      meta: { libre: true }
    },


    // TERMINOS Y CONDICIONEs
    {
      path: '/terminos',
      name: 'terminos',
      component: () => import('@/views/home/terminos'),
      meta: { libre: true }
    },
    {
      path: '/aviso',
      name: 'aviso',
      component: () => import('@/views/home/Aviso.vue'),
      meta: { libre: true }
    },

    {
      path: '/polenvio',
      name: 'polenvio',
      component: () => import('@/views/home/Polenvio.vue'),
      meta: { libre: true }
    }

  ]
})

router.beforeEach((to, from, next) => {
  // console.log(store.state.token)
  // console.log(from)
  // infica a que ruta voy a acceder
  // matched.some = compara si el meta es libre

  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.token) {
    if (to.matched.some(record => record.meta.acceso)) {
      next()
    }
  } else {
    next({
      name: 'home'
    })
  }
})

export default router
